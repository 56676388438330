.game {
  max-width: 1200px;
  margin: auto;
}

.score {
  width: 90%;
  margin-left: 5%;
  height: 80px;
  background-color: #85FFBD;
  background-image: linear-gradient(180deg, #85FFBD 0%, #FFFB7D 100%);
  background-size: 100% 100%;
  border-radius: 20px;
  margin-bottom: 10px;
}
.score .key {
  aspect-ratio: 1;
  font-size: 20px;
  width: 90px;
  display: inline-block;
}
.score .value {
  height: 60px;
  margin: 10px;
  aspect-ratio: 1;
  background-color: black;
  color: white;
  font-size: 50px;
  display: inline-block;
}
