.welcome-body {
  padding: 10px;
  background-color: #85FFBD;
  background-image: linear-gradient(180deg, #85FFBD 0%, #FFFB7D 100%);
  background-size: 100% 100%;
  max-width: calc(100% - 20px);
  height: 100vh;
}

.welcome-container {
  width: 1000px;
  margin: auto;
  display: block;
  text-align: center;
  font-size: 25px;
  max-width: 100%;
}

.welcome-container img{
  background-color: white;
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  max-width: calc(100% - 100px);
}

.welcome-container p{
  padding: 10px;
  font-size: 20px;
  max-width: calc(100% - 20px);
}
