.card {
  background-color: transparent;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  flex-basis: calc(33% - 22px);
  aspect-ratio: 1;
  margin: 10px;
  border-radius: 15px;
}

.game--10 .card {
  flex-basis: calc(25% - 22px);
}

/* This container is needed to position the front and back side */
.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.card--flipped .card-inner {
  transform: rotateY(180deg);
}

.card--matched .card-inner {
  transform: rotateY(-540deg);
}

/* Position the front and back side */
.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  border-radius: 15px;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/* Style the front side (fallback if image is missing) */
.card-front {
  background: linear-gradient(
    40deg,
    #fffc00 10%,
    #fc00ff 50%,
    #00fffc 90%
  );
  color: #fff;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.card-front img{
  margin: 10px;
  width: calc(100% - 20px);
  max-height: calc(100% - 20px);
  object-fit: contain;
}

.card-front-odd {
  background-size: 400% 400%;
  animation: gradient 2s ease infinite;
}

/* Style the back side */
.card-back {
  background: dodgerblue;
  color: white;
  transform: rotateY(180deg);
}
.card-back img{
  margin: 10px;
  width: calc(100% - 20px);
  aspect-ratio: 1;
  border-radius: 100%;
  object-fit: cover;
}

.card-back.card-back--matched {
  background: linear-gradient(
    40deg,
    #fffc00 25%,
    #fc00ff 50%,
    #00fffc75 75%
  );
  background-size: 400% 400%;
  animation: gradient 1s ease infinite;
  color: white;
  transform: rotateY(180deg);
}
